<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="customerPool-container"
  >
    <div class="title-container"><div>店铺问题记录</div></div>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>店铺名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.shopName"
          clearable
          @clear="() => clearInput()"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>客户名称:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.customerName"
          clearable
          @clear="() => clearInput()"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>所属平台:</span>
        <el-select class="common-screen-input" v-model="params.platformId" placeholder="所属平台" @change="commonFun" clearable filterable >
          <el-option v-for="item in platList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
          </el-select>
      </div>
      <div class="common-input-container">
        <span>所属类目:</span>
        <el-select class="common-screen-input" v-model="params.categoryId" placeholder="所属类目" @change="commonFun" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
          <el-option v-for="item in categoryList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
          <div class="common-select-page">
              <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
              <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
              <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
              <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
          </div>
          </el-select>
      </div>
      <br />
      <div class="common-input-container">
        <span>店铺服务状态:</span>
        <el-select
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.customerState"
          clearable
          @clear="() => clearInput()"
        >
          <el-option
            v-for="item in options3"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <common-date
          @commonGetDate="commonGetDate"
          ref="commonReset"
        ></common-date>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="() => commonFun()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <div class="gap"></div>

    <div class="common-padding">
      <el-table
      class="common-table-haveborder common-table-bgGrey th"
      :data="tableData"
      border
    >
      <el-table-column width="80px">
        <template slot-scope="scope">
          <span>
            <img
              class="common-iconImg"
              v-if="scope.row.shopLogo"
              :src="scope.row.shopLogo"
            />
            <span v-if="!scope.row.shopLogo"
              ><i class="el-icon-user common-iconImg-MR"></i
            ></span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="shopName" label="店铺名称">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="() => getShopDetail(scope.row)"
            >{{ scope.row.shopName || "--" }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="customerName"
        label="所属客户"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="platformName"
        label="所属平台"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="categoryName"
        label="所属类目"
        :formatter="tableColumn"
      >
      </el-table-column>
      <el-table-column prop="customerStateName" label="店铺服务状态">
        <template slot-scope="scope">
          <div v-if="scope.row.customerState == 3" class="common-tableStatus-grey-bgc">终止服务</div>
          <div v-if="scope.row.customerState == 1" class="common-tableStatus-green-bgc">正在服务</div>
          <div v-if="scope.row.customerState == 2" class="common-tableStatus-blue-bgc">待服务</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="createName"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="busineseUserNames"
        label="运营负责人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="110"
        :formatter="tableColumn"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>

    <shop-detail ref="shopDetail" :drawer="drawer" @handleClose="handleClose"></shop-detail>
  </div>
</template>
<script>
import { selectShopProblemList } from "../../service/service.js";
import { selectCategory, selectPlatform } from "../../service/common.js";
import { tableColumn, Config, DateTransform } from "../../utils/index.js";
import CommonDate from "../../components/common/commonDate.vue";
import ShopDetail from "./children/shopProblemDetail.vue"


export default {
  components: { CommonDate,ShopDetail },
  name: "changeRecord",
  data() {
    return {
      tableColumn,
      loading: false,
      companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司下拉
      cusId: this.$sto.get(Config.constants.userInfo).user_id, // 所属公司下拉
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        startDate:  DateTransform(new Date()),
        endDate:  DateTransform(new Date()),
      },
      tableData: [],
      categoryParams: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      platList: [], // 所属平台
      categoryList: [], //类目下拉
      options3: [
        {
          value: 1,
          label: "正在服务"
        },
        {
          value: 2,
          label: "待服务"
        },
        {
          value: 3,
          label: "终止服务"
        }
      ],
      drawer: false
    };
  },
  created() {
    if (this.companyId) {
      this.params.companyId = this.companyId;
    }
    if(this.cusId){
      this.params.adminId = this.cusId
    }
    this.selectPlatform();
    this.selectCategory();
    this.getShopProList();
  },
  mounted() {},
  methods: {
      async selectCategory() { // 类目下拉
        let categoryParams = {...this.categoryParams}
        let { data } = await selectCategory(categoryParams)
        this.categoryList = data.list
    },
    async selectPlatform() { // 平台下拉
        let { data } = await selectPlatform({pageNum: -1})
        this.platList = data
    },
    selectClear () { // 下拉清空时触发
        this.categoryParams.pageNum = 1
        this.categoryParams.name = ''
        this.selectCategory()
    },
    selectPage(type) { // 下拉分页
        if (type == 'up') {
            this.categoryParams.pageNum--
        } else {
            this.categoryParams.pageNum++
        }
        this.selectCategory()
    },
    remoteCategory(val) { // 所属类目下拉-筛选
        this.categoryParams.pageNum = 1
        this.categoryParams.name = val
        this.selectCategory()
    },
    clearInput() {
      // 筛选框清空-输入框
      this.getShopProList();
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
        this.getShopProList();
    },
    resetBtn() {
      this.$refs.commonReset.resetFun();
      // 重置
      this.params = {
        pageNum: 1,
        pageSize: 10,
        companyId: this.companyId,
        startDate:  DateTransform(new Date()),
        endDate:  DateTransform(new Date()),
      };
        this.getShopProList();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
        this.getShopProList();
    },
    async getShopProList() {
      // 获取列表
      this.loading = true;
      let params = { ...this.params };
      params.getAll = 1
      let resData = (await selectShopProblemList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.list;
      this.loading = false;
    },
    commonGetDate(startTime, endTime) {
      this.params.startDate = startTime;
      this.params.endDate = endTime;
        this.getShopProList()
    },
    getShopDetail(row){
      if(row){
        this.$refs["shopDetail"].getDataInfo(row);
        this.drawer = true;
      }
    },
    handleClose() {
      this.drawer = false;
      this.getShopProList();
    },
  }
};
</script>
<style lang="less" scoped>
.customerPool-container {
  .common-screen-container {
    padding-left: 32px;
  }
  /deep/.el-button {
    white-space: pre-line;
    text-align: left;
    line-height: 24px;
  }
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
/deep/ .el-table--border::after,
.el-table--group::after {
  width: 0 !important;
}
.gap {
  height: 16px;
  background-color: #ededed;
}
.common-screen-container {
  margin-top: 24px !important;
}
.el-tabs {
  margin-top: 32px;
}
.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
</style>
